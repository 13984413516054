
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
    name: "member-file",
    props: {
        memberFileValues: Array,
    },
    components: {},
    data() {
        return {
            cleanPageBreaks: false,
        };
    },
    methods: {
        checkPageBreak(entry: any) {
            if (entry.dues.length >= 5 && entry.promotions.length >= 3) {
                this.cleanPageBreaks = true;
                return true;
            } else {
                this.cleanPageBreaks = false;
                return false;
            }
        },
    },
});
